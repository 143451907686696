import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Curs Online Teòric per l'ISPC 37ª Promoció 2024</Title>

                <Text>
                    Et presentem el millor curs per l'accés a la 37a promoció.
                    <br />
                    <br />
                    Enguany amb una NOVETAT molt important.
                    <br />
                    <br />
                    Si ets aspirant de la 37a t'interessa aquest curs
                    <br />
                    <br />
                    Un curs amb tot el contingut teòric per a començar la 37a promoció amb bona
                    base.
                    <br />
                    <br />
                    Al curs trobaràs:
                    <br />
                    <br />
                    -Totes les Unitats Formatives
                    <br />
                    <br />
                    -Resums de cada unitat actualitzats
                    <br />
                    <br />
                    -Treballs en xarxa
                    <br />
                    <br />
                    -Exàmens
                    <br />
                    <br />
                    I la novetat d'enguany... El pòdcast de les unitats formatives més rellevants.
                    <br />
                    <br />
                    Des del primer moment que et matricules al curs, tens accés a tots els
                    continguts fins en finalitzar l'ISPC.
                    <br />
                    <br />
                    Accedeix ara per només 79,99 euros.
                </Text>
            </div>

            {/** ¿Cómo accedo? */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
